import kycApi from "../kyc-api";

const service = {
  /**
   * getQuestion
   * @param {Object} requestBody
   * @param {string} [requestBody.surveyid]
   * @returns {Object<Question>[]}
   */
  getQuestion: async (requestBody) => {
    const _params = { "survey.id": requestBody.surveyid, _sort: "group.sequence:ASC,sequence:ASC" };
    try {
      return await kycApi
        .get("/questions", { params: _params })
        .then((data) => {
          console.log(data);
          return data;
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * postQuestion
   * @param {Object} requestBody
   * @param {string} [requestBody.name]
   * @param {string} [requestBody.group]
   * @param {string} [requestBody.type]
   * @param {Object} [requestBody.answer]
   * @returns {Object<Question>}
   */
  postQuestion: async (requestBody) => {
    try {
      return await kycApi
        .post("/questions", requestBody)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * updateQuestion
   * @param {Object} requestBody
   * @param {Number} [requestBody.id]
   * @param {String} [requestBody.name]
   * @param {String} [requestBody.group]
   * @param {String} [requestBody.type]
   * @param {Object} [requestBody.answer]
   * @returns {Object<Question>}
   */
  updateQuestion: async (requestBody) => {
    const id = requestBody.id;
    let obj = requestBody.params;
    try {
      return await kycApi
        .put(`/questions/${id}`, obj)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * deleteQuestion
   * @param {Object} requestBody
   * @param {Number} [requestBody.id]
   * @returns {Object<Survey>}
   */
  deleteQuestion: async (requestBody) => {
    try {
      const id = requestBody.id;

      return await kycApi
        .delete(`/questions/${id}`)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },
};

export default service;
