import kycApi from "../kyc-api";

const service = {
  //   /**
  //    * getSurveyList
  //    * Get a full list of surveys (may need to add condition with user's company in the future)
  //    * @returns {Object<Survey>[]}
  //    */
  //   getSurveyList: async () => {
  //     try {
  //       return await kycApi
  //         .get("/surveys")
  //         .then((data) => {
  //           console.log(data);
  //           return data;
  //         })
  //         .catch((error) => {
  //           return error;
  //         });
  //     } catch (err) {
  //       return err;
  //     }
  //   },

  //   /**
  //    * getSurvey
  //    * @param {Object} requestBody
  //    * @param {string} [requestBody.surveyid]
  //    * @returns {Object<Question>[]}
  //    */
  //   getSurvey: async (requestBody) => {
  //     let params = { id: requestBody.surveyid, _limit: 1 };

  //     try {
  //       return await kycApi
  //         .get("/surveys", { params })
  //         .then((data) => {
  //           console.log(data);
  //           return data;
  //         })
  //         .catch((error) => {
  //           return error;
  //         });
  //     } catch (err) {
  //       return err;
  //     }
  //   },

  /**
   * postGroup
   * @param {Object} requestBody
   * @param {string} [requestBody.name]
   * @param {number} [requestBody.sequence]
   * @returns {Object<Survey>}
   */
  postGroup: async (requestBody) => {
    try {
      return await kycApi
        .post("/groups", requestBody)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * updateGroup
   * @param {Object} requestBody
   * @param {Number} [requestBody.id]
   * @param {string} [requestBody.name]
   * @param {Number} [requestBody.sequence]
   * @returns {Object<Survey>}
   */
  updateGroup: async (requestBody) => {
    const id = requestBody.id;
    let obj = { name: requestBody.name, sequence: requestBody.sequence };

    try {
      return await kycApi
        .put(`/groups/${id}`, obj)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * deleteGroup
   * @param {Object} requestBody
   * @param {Number} [requestBody.id]
   * @returns {Object<Survey>}
   */
  deleteGroup: async (requestBody) => {
    try {
      const id = requestBody.id;

      return await kycApi
        .delete(`/groups/${id}`)
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },
};

export default service;
