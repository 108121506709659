<template>
  <Dialog :style="{ width: '450px' }" header="Question Details" class="questionForm-wrap p-fluid">
    {{ questionForm }}

    <!-- Name Field -->
    <div class="field">
      <label class="question" for="name">Question Name</label>
      <InputText
        id="name"
        v-model.trim="questionForm.name"
        required="true"
        autofocus
        :class="{
          'p-invalid': (questionForm.name == null || questionForm.name?.length == 0) && submit,
        }"
      />
      <small class="p-invalid-label" v-if="(questionForm.name == null || questionForm.name?.length == 0) && submit">Name is required.</small>
    </div>

    <!-- Group Field -->
    <div class="field">
      <label class="mb-3 question">Group</label>
      <div class="formgrid grid">
        <div class="field-radiobutton col-6" v-for="item in group" :key="item">
          <RadioButton
            :id="item.name"
            :name="item.name"
            :value="item.id"
            v-model="questionForm.group"
            :class="{ 'p-invalid': questionForm.group == null && submit }"
          />
          <label>{{ item.name }}</label>
        </div>
      </div>
      <small class="p-invalid-label" v-if="questionForm.group == null && submit">Group is required.</small>
    </div>

    <!-- Type Field -->
    <div class="field">
      <label for="questionType" class="mb-3 question">Type</label>
      <Dropdown
        id="questionType"
        :class="{ 'p-invalid': questionForm.type == null && submit }"
        v-model="questionForm.type"
        :options="questionType"
        placeholder="Select a Question Type"
      />
      <small class="p-invalid-label" v-if="questionForm.type == null && submit">Type is required.</small>
    </div>

    <!-- MC Field: Choice/Condition/Parameter/Attribute -->
    <div class="mc-wrap" v-if="questionForm.type == 'Single Choice Question' || questionForm.type == 'Multiple Choice Question'">
      <div class="button-container">
        <Button icon="pi pi-plus" @click="addQuestionChoice(questionForm)" />
      </div>

      <div v-for="(item, index) of questionForm.answer.options" :key="item" class="field">
        <label class="question" for="name">Choice</label>
        <div class="flex">
          <!-- Choice Input Field -->
          <InputText
            id="name"
            v-model.trim="item.choice"
            required="true"
            autofocus
            @change="updateKey(index)"
            :class="{
              'p-invalid': (item.choice == null || item.choice?.length == 0) && submit,
            }"
          />

          <!-- Attribute Score Input Field -->
          <InputText
            v-if="questionForm.key == true"
            type="number"
            v-model.trim="questionForm.answer.attribute.input[index].score"
            style="margin-left: 0.5rem"
            placeholder="Score"
          />

          <!-- Condition Input Field -->
          <Dropdown
            style="margin-left: 0.5rem"
            v-if="mcCondition"
            v-model="questionForm.answer.condition[index].question"
            :options="conditionInGroup"
            optionLabel="name"
            placeholder="Select a Question"
          />

          <div class="col-2">
            <Button icon="pi pi-minus" @click="minusQuestionChoice(questionForm.answer.options.indexOf(item))" />
          </div>
        </div>
        <small class="p-invalid-label" v-if="(item.choice == null || item.choice?.length == 0) && submit">Choice name is required.</small>
      </div>

      <!-- Condition Setting Field -->
      <div class="p-field-checkbox">
        <InputSwitch class="profile" id="binary" v-model="mcCondition" :binary="true" />
        <label for="binary" v-if="!mcCondition"> No Additional Condition</label>
        <label for="binary" v-if="mcCondition"> Required Additional Condition</label>
      </div>
    </div>

    <!-- Long Question Field: Answer Type/Cndition/Parameter/Attribute-->
    <div
      class="longq-wrap"
      v-if="questionForm.type != null && questionForm.type != 'Single Choice Question' && questionForm.type != 'Multiple Choice Question'"
    >
      <div class="field">
        <label for="dataType" class="mb-3 question">Answer Input Type</label>

        <!-- Answer Type Input Field -->
        <Dropdown
          id="questionType"
          :class="{
            'p-invalid': questionForm.answer?.dataType == null && submit,
          }"
          v-model="questionForm.answer.dataType"
          :options="dataType"
          placeholder="Select a Answer Type"
        />
        <small class="p-invalid-label" v-if="questionForm.answer?.dataType == null && submit">Answer type is required.</small>

        <!-- Condition Setting Field -->
        <div class="p-field-checkbox">
          <InputSwitch class="profile" id="binary" v-model="textQuestionCondition" :binary="true" />

          <label for="binary" v-if="!textQuestionCondition"> No Additional Condition</label>
          <label for="binary" v-if="textQuestionCondition"> Required Additional Condition</label>
        </div>

        <!-- Condition Input Field -->
        <div class="field" v-if="textQuestionCondition">
          <div class="button-container">
            <Button icon="pi pi-plus" @click="addCondition(this.questionForm)" />
          </div>
          <div class="condition" v-for="item in questionForm.answer?.condition" :key="item">
            <label for="condition" class="mb-3 question">Condition</label>
            <div class="condition-container">
              <div class="col-5">
                <InputText
                  placeholder="key"
                  v-model="item.key"
                  :class="{
                    'p-invalid': (item.key == null || item.key?.length == 0) && submit,
                  }"
                />
              </div>
              <div class="col-5">
                {{ item.question }}
                <Dropdown v-model="item.question" :options="conditionInGroup" optionLabel="name" placeholder="Select a Question" />
              </div>
              <div class="col-2">
                <Button icon="pi pi-minus" @click="minusCondition(this.questionForm.answer.condition.indexOf(item))" />
              </div>
            </div>
            <small class="p-invalid-label" v-if="(item.value == null || item.value?.length == 0 || item.key == null || item.key?.length == 0) && submit"
              >contition is required.</small
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Parameter Setting Field: for both mc and text question-->
    <div class="field">
      <div class="p-field-checkbox">
        <InputSwitch class="profile" id="binary" v-model="additionalParameter" :binary="true" />
        <label for="binary" v-if="!additionalParameter"> No Additional Parameters</label>
        <label for="binary" v-if="additionalParameter"> Required Additional Parameters</label>
      </div>

      <!-- Parameter Input Field -->
      <div class="field" v-if="additionalParameter">
        <div class="button-container">
          <Button icon="pi pi-plus" @click="addParameter(this.questionForm)" />
        </div>
        <div class="parameter" v-for="item in questionForm.answer?.params" :key="item">
          <label for="parameter" class="mb-3 question">Parameter</label>
          <div class="parameter-container">
            <div class="col-5">
              <InputText
                placeholder="Key(e.g. max,min)"
                v-model="item.key"
                :class="{
                  'p-invalid': (item.key == null || item.key?.length == 0) && submit,
                }"
              />
            </div>
            <div class="col-5">
              <InputText
                placeholder="Value"
                :class="{
                  'p-invalid': (item.value == null || item.value?.length == 0) && submit,
                }"
                v-model="item.value"
              />
            </div>
            <div class="col-2">
              <Button icon="pi pi-minus" @click="minusParameter(this.questionForm.answer.params.indexOf(item))" />
            </div>
          </div>
          <small class="p-invalid-label" v-if="(item.value == null || item.value?.length == 0 || item.key == null || item.key?.length == 0) && submit"
            >Parameter is required.</small
          >
        </div>
      </div>
    </div>

    <!-- Key Field -->
    <div class="field">
      <label for="key" class="mb-3 question">Is the question related to respondent's profile?</label>
      <div class="p-field-checkbox">
        <InputSwitch class="profile" id="binary" v-model="questionForm.key" :binary="true" />
        <label for="binary" v-if="!questionForm.key">Non-profile</label>
        <label for="binary" v-if="questionForm.key">profile</label>
      </div>

      <!-- Varible Field -->
      <div v-if="questionForm.key == true" class="field">
        <label class="question" for="name">Attribute Variable</label>
        <InputText
          :class="{
            'p-invalid': (attributeVariable == null || attributeVariable?.length == 0) && submit,
          }"
          placeholder="Variable"
          v-model="attributeVariable"
        />
        <small class="p-invalid-label" v-if="(attributeVariable == null || attributeVariable?.length == 0) && submit">Variable is required.</small>
      </div>

      <!-- Long Question Attribute Field -->

      <div
        class="field"
        v-if="
          questionForm.type != null &&
          questionForm.type != 'Single Choice Question' &&
          questionForm.type != 'Multiple Choice Question' &&
          questionForm.key == true
        "
      >
        <div class="button-container">
          <Button icon="pi pi-plus" @click="addAttribute(this.questionForm)" />
        </div>
        <div class="attribute" v-for="item in questionForm.answer.attribute?.input" :key="item">
          <label for="attribute" class="mb-3 question">Profile Attribute</label>
          <div class="attribute-container">
            <div class="col-5">
              <InputText
                placeholder="Key(e.g. 'age 60')"
                v-model="item.key"
                :class="{
                  'p-invalid': (item.key == null || item.key?.length == 0) && submit,
                }"
              />
            </div>
            <div class="col-5">
              <InputText
                placeholder="Score"
                type="number"
                :class="{
                  'p-invalid': (item.score == null || item.score?.length == 0) && submit,
                }"
                v-model="item.score"
              />
            </div>
            <div class="col-2">
              <Button icon="pi pi-minus" @click="minusAttribute(this.questionForm.answer.attribute.input.indexOf(item))" />
            </div>
          </div>
          <small class="p-invalid-label" v-if="(item.score == null || item.score?.length == 0 || item.key == null || item.key?.length == 0) && submit"
            >Attribute is required.</small
          >
        </div>
      </div>
    </div>

    <!-- Question appear condition-->
    <div class="field">
      <label for="dataType" class="mb-3 question">Quesion Condition</label>

      <div class="p-field-checkbox">
        <InputSwitch class="profile" id="binary" v-model="questionCondition" :binary="true" />
        <label for="binary" v-if="!questionCondition">Question will always appear</label>
        <label for="binary" v-if="questionCondition">Question will only appear under certain conditions</label>
      </div>
      <div class="field" v-if="questionCondition">
        <div class="button-container">
          <Button icon="pi pi-plus" @click="addQuestionCondition(this.questionForm)" />
        </div>
        <div class="attribute" v-for="item in questionForm.condition" :key="item">
          <label for="attribute" class="mb-3 question">Question Condition</label>
          <div class="attribute-container">
            <div class="col-5">
              <InputText
                placeholder="Variable"
                v-model="item.variable"
                :class="{
                  'p-invalid': (item.variable == null || item.variable?.length == 0) && submit,
                }"
              />
            </div>
            <div class="col-5">
              <InputText
                placeholder="Value"
                :class="{
                  'p-invalid': (item.value == null || item.value?.length == 0) && submit,
                }"
                v-model="item.value"
              />
            </div>
            <div class="col-2">
              <Button icon="pi pi-minus" @click="minusQuestionCondition(this.questionForm.condition.indexOf(item))" />
            </div>
          </div>
          <small class="p-invalid-label" v-if="(item.value == null || item.value?.length == 0 || item.variable == null || item.variable?.length == 0) && submit"
            >Condition is required.</small
          >
        </div>
      </div>
    </div>

    <!-- Sequence Field -->
    <div class="field">
      <label for="dataType" class="mb-3 question">Sequence in Group</label>

      <OrderList v-model="questionInGroup" list-style="height:auto" data-key="id">
        <template #header> Please arrange the sequence of questions. </template>
        <template #item="slotProps">
          <div class="product-item">
            <h6 class="p-mb-2">
              {{ slotProps.item.name }}
            </h6>
          </div>
        </template>
      </OrderList>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeForm()" />
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="submitForm()" />
    </template>
  </Dialog>
</template>

<script>
export default {
  data() {
    return {
      mode: null,
      questionForm: {},
      questionType: ["Single Choice Question", "Multiple Choice Question", "Long Question", "Short Question"],
      dataType: ["Integer", "Double", "String"],
      questionInGroup: [],
      conditionInGroup: [],
      additionalParameter: false,
      textQuestionCondition: false,
      mcCondition: false,
      attributeVariable: null,
      questionCondition: false,
      submit: false,
    };
  },
  props: {
    question: Object,
    questionList: Array,
    group: Array,
  },

  watch: {
    // clone question to questionForm
    // set additionalParameter as false
    question() {
      this.questionForm = Object.assign({}, this.question);
      console.log(this.questionForm);
      console.log(this.group);
      this.textQuestionCondition = false;
      this.mcCondition = false;
      this.additionalParameter = false;
      this.attributeVariable = null;
      this.questionCondition = this.questionForm.condition == null ? false : true;
      if (this.questionForm.id != null) {
        if (this.questionForm.answer != null) {
          if ("attribute" in this.questionForm.answer) {
            this.attributeVariable = this.questionForm.answer.attribute.variable;
          }
          if ("params" in this.questionForm.answer) {
            this.additionalParameter = true;
          }
          if ("condition" in this.questionForm.answer) {
            this.textQuestionCondition = true;
          }
        } else if (this.questionForm.answer.options != null) {
          if (this.questionForm.key == true) {
            this.attributeVariable = this.questionForm.answer.options[0].attribute.variable;
          }
          if ("condition" in this.questionForm.answer) {
            this.mcCondition = true;
          }
        }
      }
      this.questionInGroup = [];
      this.conditionInGroup = [];
      this.submit = false;
      this.mode = this.questionForm.id == null ? "save" : "update";
      console.log(this.questionList);
    },

    // watch type of questionForm
    "questionForm.type": {
      handler: function () {
        //MC CASE
        if (this.questionForm.type == "Single Choice Question" || this.questionForm.type == "Multiple Choice Question") {
          // this.questionForm.answer = null;
          // create choice
          if (this.questionForm.answer.options == null) {
            this.questionForm.answer.options = [
              {
                choice: null,
              },
            ];
            if (this.questionForm.key == true) {
              this.questionForm.answer.attribute = {
                input: [
                  {
                    key: null,
                    score: null,
                  },
                ],
              };
            }
          }

          // if it is a profile quuestion, create attribute
        }
        //LONGQ CASE
        else {
          this.questionForm.answer.options = null;
          // create dataType
          if (this.questionForm.answer == null) {
            this.questionForm.answer = {
              dataType: null,
            };
          }

          // if it is a profile question, create attribute
          if (this.questionForm.key == true) {
            if (!("attribute" in this.questionForm.answer)) {
              this.questionForm.answer.attribute = {
                variable: this.attributeVariable,
                input: [
                  {
                    key: null,
                    score: null,
                  },
                ],
              };
            }
          }
        }
      },
      deep: true,
    },
    questionCondition() {
      if (this.questionCondition) {
        if (this.questionForm.condition == null) {
          this.questionForm.condition = [
            {
              variable: null,
              value: null,
            },
          ];
        }
      } else {
        this.questionForm.condition = null;
      }
    },
    // watch additionalParameter
    additionalParameter() {
      if (this.additionalParameter) {
        if (this.questionForm.answer != null) {
          if (!("params" in this.questionForm.answer)) {
            // if additional parameter is needed, create params
            this.questionForm.answer.params = [
              {
                key: null,
                value: null,
              },
            ];
          }
        }
      } else {
        delete this.questionForm.answer?.params;
      }
    },

    // watch condition
    textQuestionCondition() {
      if (this.textQuestionCondition) {
        if (this.questionForm.answer != null) {
          if (!("condition" in this.questionForm.answer)) {
            // if additional parameter is needed, create params
            this.questionForm.answer.condition = [
              {
                key: null,
                question: null,
              },
            ];
          }
          console.log(this.questionForm.answer.condition);
        }
      } else {
        if (this.questionForm.answer != null) {
          if ("condition" in this.questionForm.answer) delete this.questionForm.answer.condition;
        }
      }
    },

    // watch condition
    mcCondition() {
      if (this.mcCondition) {
        if (!("condition" in this.questionForm.answer)) {
          console.log(this.questionForm.answer.options.length);
          this.questionForm.answer.condition = [];
          this.questionForm.answer.options.forEach((option, index) => {
            let condition = {
              key: this.questionForm.answer.options[index].choice,
              question: null,
            };
            this.questionForm.answer.condition.push(condition);
          });
        }
        console.log("hi");
      } else {
        if (this.questionForm.answer != null) {
          if ("condition" in this.questionForm.answer) delete this.questionForm.answer.condition;
        }
      }
    },

    // watch attrobuuteVaribale and relocate to questionForm
    attributeVariable() {
      if (this.questionForm.type == "Single Choice Question" || this.questionForm.type == "Multiple Choice Question") {
        this.questionForm.answer.attribute.variable = this.attributeVariable;
      } else if (this.questionForm.type != null) {
        console.log(this.attributeVariable);
        if (!this.questionForm.answer.attribute) {
          this.questionForm.answer.attribute = {};
        }
        this.questionForm.answer.attribute.variable = this.attributeVariable;
      }
    },

    // watch group to make questionInGroup for sequence
    "questionForm.group": {
      handler: function () {
        if (this.questionForm.group != null) {
          this.questionInGroup = [];
          this.conditionInGroup = [];
          let group = this.questionForm.group;
          this.questionList.forEach((question) => {
            if (question.group.id == group) {
              this.questionInGroup.push(question);
              let obj = {
                name: question.name,
                id: question.id,
                sequence: question.sequence,
              };
              this.conditionInGroup.push(obj);
            }
          });
          if (this.questionForm.id == null) {
            this.questionInGroup.push(this.questionForm);
          }
          this.conditionInGroup = this.conditionInGroup.filter((item) => {
            console.log(item.name);
            console.log(this.questionForm.name);
            return item.name != this.questionForm.name && item.sequence > this.questionForm.sequence;
          });
        }
      },
      deep: true,
    },

    // watch key
    "questionForm.key": {
      handler: function () {
        if (this.questionForm.key) {
          if (this.questionForm.type == "Single Choice Question" || this.questionForm.type == "Multiple Choice Question") {
            if (!("attribute" in this.questionForm.answer)) {
              this.questionForm.answer.attribute = {
                variable: this.attributeVariable,
                input: [],
              };
              this.questionForm.answer.options.forEach((option) => {
                let obj = { key: option.choice, score: null };
                this.questionForm.answer.attribute.input.push(obj);
              });
            }
          } else if (this.questionForm.type != null) {
            if (!("attribute" in this.questionForm.answer)) {
              this.questionForm.answer.attribute = {
                variable: this.attributeVariable,
                input: [
                  {
                    key: null,
                    score: null,
                  },
                ],
              };
            }
          }
        } else {
          if (this.questionForm.type == "Single Choice Question" || this.questionForm.type == "Multiple Choice Question") {
            delete this.questionForm.answer.attribute;
          } else if (this.questionForm.type != null) {
            delete this.questionForm.answer.attribute;
          }
        }
      },
      deep: true,
    },
  },

  methods: {
    addQuestionChoice(question) {
      let obj = {
        choice: null,
      };
      question.answer.options.push(obj);

      if (this.mcCondition) {
        console.log("hihi");
        let obj = {
          key: null,
          question: null,
        };
        question.answer.condition.push(obj);
      }

      if (question.key == true) {
        let obj = {
          key: null,
          score: null,
        };
        question.answer.attribute.input.push(obj);
      }
    },
    minusQuestionChoice(index) {
      if (this.questionForm.answer.options.length > 1) {
        this.questionForm.answer.options.splice(index, 1);
      }
      if (this.mcCondition) {
        this.questionForm.answer.condition.splice(index, 1);
      }
      if (this.questionForm.key == true) {
        this.questionForm.answer.attribute.splice(index, 1);
      }
    },
    addParameter(question) {
      let obj = {
        key: null,
        value: null,
      };
      question.answer.params = [...question.answer.params, obj];
    },
    minusParameter(index) {
      if (this.questionForm.answer.params.length > 1) {
        this.questionForm.answer.params.splice(index, 1);
      }
    },
    addCondition(question) {
      let obj = {
        key: null,
        question: null,
      };
      question.answer.condition = [...question.answer.condition, obj];
    },
    minusCondition(index) {
      if (this.questionForm.answer.condition.length > 1) {
        this.questionForm.answer.condition.splice(index, 1);
      }
    },
    addQuestionCondition(question) {
      let obj = {
        variable: null,
        value: null,
      };
      question.condition.push(obj);
    },
    minusQuestionCondition(index) {
      if (this.questionForm.condition.length > 1) {
        this.questionForm.condition.splice(index, 1);
      }
    },
    addAttribute(question) {
      let obj = {
        key: null,
        score: null,
      };
      question.answer.attribute.input = [...question.answer.attribute.input, obj];
    },
    minusAttribute(index) {
      console.log(index);
      if (this.questionForm.answer.attribute.input.length > 1) {
        this.questionForm.answer.attribute.input.splice(index, 1);
      }
    },

    closeForm() {
      this.$emit("close");
    },
    submitForm() {
      this.submit = true;
      console.log(this.mode);
      if (this.validateQuestion(this.questionForm)) {
        this.$emit("submit", this.questionForm, this.questionInGroup, this.mode);
      }
    },
    validateQuestion(question) {
      let checkName = false;
      let checkGroup = false;
      let checkType = false;
      let checkAnswer = false;
      let checkCondition = false;

      // check question name
      if (question.name != null) {
        if (question.name.length > 0) {
          checkName = true;
        }
      }
      // check question group
      checkGroup = question.group != null ? true : false;
      // check question type
      checkType = question.type != null ? true : false;

      // check question condition
      checkCondition = true;
      if (question.condition != null) {
        question.condition.forEach((condition) => {
          if (condition.variable == null || condition.value == null) {
            checkCondition = false;
          } else if (condition.variable.length == 0 || condition.value.length == 0) {
            checkCondition = false;
          }
        });
      }
      // check answer for MC

      if (question.type == "Single Choice Question" || question.type == "Multiple Choice Question") {
        checkAnswer = true;
        question.answer.options?.forEach((choice) => {
          if (choice.choice == null) {
            checkAnswer = false;
          }
        });
        if (question.key == true) {
          if (question.answer.attribute?.variable == null) {
            checkAnswer = false;
          } else if (question.answer.attribute?.variable.length == 0) {
            checkAnswer = false;
          }

          question.answer.attribute?.input.forEach((input) => {
            if (input.key == null || input.score == null) {
              checkAnswer = false;
            } else if (input.key.length == 0 || input.score.length == 0) {
              checkAnswer = false;
            }
          });
        }
      }
      // check answer for text question
      else {
        let checkDataType = question.answer.dataType == null ? false : true;
        let checkParams = true;
        question.answer.params?.forEach((param) => {
          if (param.key == null || param.value == null) {
            checkParams = false;
          } else if (param.key.length == 0 || param.value.length == 0) {
            checkParams = false;
          }
        });
        let checkCondition = true;
        question.answer.condition?.forEach((param) => {
          if (param.key == null || param.question == null) {
            checkCondition = false;
          } else if (param.key.length == 0 || param.question.length == 0) {
            checkCondition = false;
          }
        });

        let checkAttribute = true;
        if (question.key == true) {
          checkAttribute = question.answer.attribute.varible == null ? false : true;
          checkAttribute = question.answer.attribute.varible?.length < 1 ? false : true;

          question.answer.attribute.input.forEach((input) => {
            if (input.key == null || input.score == null) {
              checkAttribute = false;
            } else if (input.key.length == 0 || input.score.length == 0) {
              checkAttribute = false;
            }
          });
        }
        checkAnswer = checkDataType && checkParams && checkCondition && checkAttribute ? true : false;
      }
      console.log(checkName, checkGroup, checkType, checkAnswer);
      return checkName && checkGroup && checkType && checkAnswer && checkCondition ? true : false;
    },
    //update attribute key for mc question when option[index].choice change
    //update condition key
    updateKey(index) {
      if ("attribute" in this.questionForm.answer) {
        this.questionForm.answer.attribute.input[index].key = this.questionForm.answer.options[index].choice;
      }
      if ("condition" in this.questionForm.answer) {
        this.questionForm.answer.condition[index].key = this.questionForm.answer.options[index].choice;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.questionForm-wrap {
  .p-invalid-label {
    color: red;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
  .field {
    .question {
      font-weight: bold;
    }
    .flex {
      height: 2.5rem;
      align-items: center;
      .p-dropdown {
        width: 11.5rem;
        height: 2.35rem;
      }
      .p-button {
        margin-left: 0.5rem;
      }
    }
    .p-field-checkbox {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      .profile {
        margin-right: 0.5rem;
      }
    }
    .parameter,
    .attribute,
    .condition {
      .parameter-container,
      .attribute-container,
      .condition-container {
        display: flex;
      }
    }
  }
}
</style>
