<template>
  <div class="grid question-wrap">
    <Button class="back-btn" @click="$router.replace('/survey')"> <i class="pi pi-arrow-left" />Survey </Button>
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template #left>
            <div class="my-2">
              <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openCreateSurveyForm" />
              <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="(deletedQuestion = selectedQuestion), (display.deletedQuestionForm = true)"
              />
            </div>
          </template>
        </Toolbar>

        <!----- Table display all questions ------>
        <DataTable
          :value="questionList"
          row-group-mode="rowspan"
          group-rows-by="group.name"
          data-key="id"
          :loading="loading"
          v-model:selection="selectedQuestion"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          filter-display="menu"
          :multi-sort-meta="sort"
          sort-mode="multiple"
          sort-field="group.name"
          responsive-layout="scroll"
        >
          <Column selection-mode="multiple" header-style="width: 3rem" />
          <Column header="#" header-style="width:3em">
            <template #body="slotProps">
              {{ slotProps.data.sequence }}
            </template>
          </Column>
          <Column field="group.name" header="Group">
            <template #body="slotProps">
              <div class="group-container">
                <div>{{ slotProps.data.group.name }}</div>
                <Button icon="pi pi-pencil" class="button-edit" v-tooltip.bottom="'Edit Group'" @click="openEditGroupForm(slotProps.data)" />
              </div>
            </template>
            <template #filter="{ filterModel }">
              <div class="p-mb-3 p-text-bold">Group Picker</div>
              <Dropdown v-model="filterModel.value" :options="groups" placeholder="Select Group" class="p-column-filter">
                <template #option="slotProps">
                  <div class="p-multiselect-representative-option">
                    <span class="image-text">{{ slotProps.option }}</span>
                  </div>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="name" header="Name" :sortable="true">
            <template #body="{ data }">
              {{ data.name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name" />
            </template>
          </Column>
          <Column field="type" header="Type" :sortable="true">
            <template #body="{ data }">
              {{ data.type }}
            </template>
            <template #filter="{ filterModel }">
              <div class="p-mb-3 p-text-bold">Group Picker</div>
              <Dropdown v-model="filterModel.value" :options="questionType" placeholder="Select Type" class="p-column-filter">
                <template #option="slotProps">
                  <div class="p-multiselect-representative-option">
                    <span class="image-text">{{ slotProps.option }}</span>
                  </div>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="choice" header="Choice">
            <template #body="slotProps">
              {{ slotProps.data.answer.options != null ? null : "-" }}
              <div v-for="item of slotProps.data.answer.options" :key="item">
                <b>Choice:</b><br />
                <label>{{ item.choice }}</label> <br />
                <div v-if="item.attribute != null">
                  <b>Score:</b>
                  {{ item.attribute.score }}<br />
                  <b>Variable:</b><br />
                  {{ item.attribute.variable }}
                </div>
                <div v-if="item.condition != null">
                  <b>Question:</b><br />
                  {{ item.condition.question?.name }}
                </div>
                <br />
              </div>
            </template>
          </Column>
          <Column field="answer" header="Answer Type">
            <template #body="slotProps">
              <div v-if="slotProps.data.answer?.dataType == null">-</div>
              <div v-if="slotProps.data.answer?.dataType != null">
                <b>Data Type:</b>

                {{ slotProps.data.answer.dataType }}
              </div>

              <div v-if="slotProps.data.answer?.params != null">
                <b>Params:</b>
                <div v-for="item in slotProps.data.answer.params" :key="item">
                  {{ item.key + ": " + item.value }}
                </div>
              </div>
              <div v-if="slotProps.data.answer?.attribute != null">
                <b>Attribute:</b>
                {{ "Variable: " + slotProps.data.answer.attribute.variable }}
                <div v-for="item in slotProps.data.answer.attribute.input" :key="item">
                  {{ item.key + ": " + item.score }}
                </div>
              </div>
              <div v-if="slotProps.data.answer?.condition != null">
                <b>Condition:</b>
                <div v-for="item in slotProps.data.answer.condition" :key="item">
                  {{ item.key + ": " + item.question.name }}
                </div>
              </div>
              <br />
            </template>
          </Column>
          <Column field="key" header="Key" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.key }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="button-container">
                <Button icon="pi pi-pencil" class="button-edit" v-tooltip.bottom="'Edit Question'" @click="openEditQuestionForm(slotProps.data)" />
                <Button
                  icon="pi pi-trash"
                  class="button-delete"
                  v-tooltip.bottom="'Delete Question'"
                  @click="(deletedQuestion = slotProps.data), (display.deletedQuestionForm = true)"
                />
              </div>
            </template>
          </Column>
          <Column field="Condition" header="Condition" :sortable="true">
            <template #body="slotProps">
              <!-- may delete the v-if afterwards, since the questions now are not updated -->

              <div v-if="'condition' in slotProps.data">{{ slotProps.data.condition == null ? "-" : slotProps.data.condition }}</div>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <div class="button-container">
                <Button icon="pi pi-pencil" class="button-edit" v-tooltip.bottom="'Edit Question'" @click="openEditQuestionForm(slotProps.data)" />
                <Button
                  icon="pi pi-trash"
                  class="button-delete"
                  v-tooltip.bottom="'Delete Question'"
                  @click="(deletedQuestion = slotProps.data), (display.deletedQuestionForm = true)"
                />
              </div>
            </template>
          </Column>
        </DataTable>

        <!----- Create Question Form ----->
        <QuestionForm
          v-model:visible="display.createQuestionForm"
          :question="question"
          :group="groupList"
          :questionList="questionList"
          @close="display.createQuestionForm = false"
          @submit="submitQuestion"
        />

        <!----- Edit Question Form ----->
        <QuestionForm
          v-model:visible="display.editQuestionForm"
          :question="editQuestion"
          :group="groupList"
          :questionList="questionList"
          @close="display.editQuestionForm = false"
          @submit="submitQuestion"
        />

        <!----- Delete Question Confirmation Form ----->
        <Dialog v-model:visible="display.deletedQuestionForm" :style="{ width: '450px' }" header="Delete Survey" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Are you Sure to delete this question?</label>
          </div>
          <template #footer>
            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteQuestion" />
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="(deletedQuestion = null), (display.deletedQuestionForm = false)" />
          </template>
        </Dialog>

        <!------ Edit Group Form ------->
        <Dialog v-model:visible="display.editGroupForm" :style="{ width: '450px' }" header="Question Details" :modal="true" class="p-fluid">
          <div class="field">
            <label class="question" for="name">Group Name</label>
            <InputText
              id="name"
              v-model.trim="editGroup.name"
              required="true"
              autofocus
              :class="{
                'p-invalid': (editGroup.name == null || editGroup.name?.length == 0) && submit,
              }"
            />
            <small class="p-invalid-label" v-if="(editGroup.name == null || editGroup.name?.length == 0) && submit">Name is required.</small>
          </div>
          <OrderList v-model="groupSequence" list-style="height:auto" data-key="id">
            <template #header> Please arrange the sequence of groups. </template>
            <template #item="slotProps">
              <div class="product-item">
                <h6 class="p-mb-2">
                  {{ slotProps.item.name }}
                </h6>
              </div>
            </template>
          </OrderList>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="this.display.editGroupForm = false" />
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="updateGroupSequence()" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionForm from "../../components/survey/QuestionForm.vue";
import surveyService from "../../service/survey";
import groupService from "../../service/group";
import questionService from "../../service/question";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  data() {
    return {
      surveyid: this.$route.params.surveyid,
      survey: {},
      groupList: [],
      filters: {
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        group: { value: null, matchMode: FilterMatchMode.CONTAINS },
        type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      question: {},
      editQuestion: { key: false },
      editGroup: {},
      display: {
        createQuestionForm: false,
        deletedQuestionForm: false,
        editQuestionForm: false,
        editGroupForm: false,
      },
      groupSequence: [],
      selectedQuestion: null,
      questionList: null,
      deletedQuestion: null,
      loading: true,
      sort: [
        { field: "group.sequence", order: 1 },
        { field: "sequence", order: 1 },
      ],
    };
  },
  mounted() {
    surveyService.getSurvey({ surveyid: this.surveyid }).then((result) => {
      this.survey = result[0];
      this.groupList = this.survey.groups;
      this.updateTable();
    });
  },
  components: {
    QuestionForm,
  },
  watch: {
    "display.editGroupForm": {
      handler: function () {
        if (this.display.editGroupForm == true) {
          this.groupSequence = [];
          this.groupList.forEach((group) => {
            this.groupSequence.push(group);
          });
          this.groupSequence.sort((a, b) => {
            return a.sequence - b.sequence;
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    updateTable() {
      this.loading = true;
      let obj = { surveyid: this.surveyid };
      let promises = [];
      promises.push(surveyService.getSurvey(obj));
      promises.push(questionService.getQuestion(obj));
      Promise.all(promises).then((values) => {
        console.log(values);
        this.groupList = values[0][0].groups;
        this.questionList = values[1];
        this.loading = false;
      });
    },
    openCreateSurveyForm() {
      this.question = {
        name: null,
        group: null,
        sequence: null,
        type: null,
        answer: {},
        key: false,
        id: null,
        condition: null,
      };

      this.display.createQuestionForm = true;
    },
    openEditQuestionForm(data) {
      let copyData = JSON.parse(JSON.stringify(data));

      this.editQuestion = {
        name: copyData.name,
        group: copyData.group.id,
        sequence: copyData.sequence,
        type: copyData.type,
        answer: copyData.answer,
        id: copyData.id,
        condition: copyData.condition,
      };
      this.editQuestion.key = data.key == "profile" ? true : false;
      this.display.editQuestionForm = true;
    },
    openEditGroupForm(data) {
      console.log(data);
      this.groupSequence = [];
      this.submit = false;
      this.display.editGroupForm = true;
      this.editGroup = {
        name: data.group.name,
        id: data.group.id,
      };
    },
    submitQuestion(questionForm, questionInGroup, mode) {
      this.loading = true;
      this.updateSequence(questionForm, questionInGroup, mode);
      this.display.createQuestionForm = false;
      this.display.editQuestionForm = false;
    },
    updateSequence(questionForm, questionInGroup, mode) {
      let currentQuestionSequence;
      let promises = [];
      questionInGroup.forEach((question, index) => {
        if (mode == "save") {
          if (question.name != questionForm.name) {
            let obj = { id: question.id, params: { sequence: index + 1 } };
            promises.push(questionService.updateQuestion(obj));
          } else {
            currentQuestionSequence = index + 1;
          }
        } else if (mode == "update") {
          let obj = { id: question.id, params: { sequence: index + 1 } };
          console.log(question, index);
          promises.push(questionService.updateQuestion(obj));
        }
      });
      Promise.all(promises).then(() => {
        if (mode == "save") this.saveQuestion(questionForm, currentQuestionSequence);
        else if (mode == "update") {
          this.updateQuestion(questionForm);
        }
      });
    },

    saveQuestion(questionForm, currentSequence) {
      console.log(questionForm);
      let obj = {
        name: questionForm.name,
        group: questionForm.group,
        sequence: currentSequence,
        type: questionForm.type,
        answer: questionForm.answer,
        condition: questionForm.condition,
        survey: this.surveyid,
      };
      obj.key = questionForm.key ? "profile" : "Non-profile";
      questionService.postQuestion(obj).then(() => {
        this.updateTable();
      });
    },
    updateQuestion(questionForm) {
      let obj = {
        params: {
          name: questionForm.name,
          group: questionForm.group,
          type: questionForm.type,
          answer: questionForm.answer,
          condition: questionForm.condition,
        },
        id: questionForm.id,
      };
      obj.params.key = questionForm.key ? "profile" : "Non-profile";
      questionService.updateQuestion(obj).then(() => {
        this.updateTable();
      });
    },

    deleteQuestion() {
      this.loading = true;

      this.display.deletedQuestionForm = false;
      this.deletedQuestion = Array.isArray(this.deletedQuestion) ? this.deletedQuestion : Array(this.deletedQuestion);
      console.log(this.deletedQuestion);
      let promises = [];
      this.deletedQuestion.forEach((question) => {
        let obj = { id: question.id };
        promises.push(questionService.deleteQuestion(obj));
      });

      Promise.all(promises).then(() => {
        this.updateTable();
        this.deletedQuestion = null;
      });
    },
    updateGroup() {
      let obj = {
        name: this.editGroup.name,
        id: this.editGroup.id,
      };
      groupService.updateGroup(obj).then(() => {
        this.updateTable();
      });
    },
    updateGroupSequence() {
      this.submit = true;
      if (this.editGroup.name != null) {
        if (this.editGroup.name.length > 0) {
          this.loading = true;
          this.display.editGroupForm = false;
          let promises = [];
          this.groupSequence.forEach((group, index) => {
            let obj = { id: group.id, sequence: index + 1 };
            promises.push(groupService.updateGroup(obj));
          });
          Promise.all(promises).then(() => {
            this.updateGroup();
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.field {
  margin-top: 0.5rem;
  .paramter-container,
  .attribute-container {
    display: flex;
    .p-invalid {
      border-color: red;
    }
  }
}
.profile {
  margin-right: 0.5rem;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 0.5rem;
  }
}
.question {
  font-weight: bold;
}
.p-invalid-label {
  color: red;
}
.question-wrap {
  .back-btn {
    background: transparent;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
    border-color: transparent;
    font-size: 1.2rem;
  }
  .p-datatable-tbody {
    td:nth-child(3) {
      text-align: center;
    }
    td {
      .p-tag {
        background-color: #fbc02d;
        &.assess {
          background-color: #2192f3;
        }
      }
      .button-edit {
        background: #a9a9a9;
        border-color: #a9a9a9;
        border-radius: 30%;
      }
      .button-delete {
        background: #4c4c4c;
        border-color: #4c4c4c;
        border-radius: 30%;
      }
      .group-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }
}
</style>
